'use client'

import { useState, useEffect, useRef } from 'react'
import { motion, useScroll, useTransform, useSpring, AnimatePresence, MotionValue, useAnimation } from 'framer-motion'
import { ArrowDown, CheckCircle, Calendar, Clock, BarChart, Coffee, Download, Moon, Sun, Target, ChevronLeft, ChevronRight, X, Zap, Check, Layers, Activity } from 'lucide-react'
import { useInView } from 'react-intersection-observer'




// Define types for props
interface HeaderProps {
  isDarkMode: boolean;
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

interface HeroProps {
  isDarkMode: boolean;
}

interface FeaturesProps {
  isDarkMode: boolean;
}

interface FeatureSectionProps {
  feature: {
    title: string;
    description: string;
    icon: React.ElementType;
    color: string;
    details: { icon: string; text: string }[];
    tip: string;
    video: string;
  };
  index: number;
  isDarkMode: boolean;
}

interface VideoPlayerProps {
  src: string;
  fallbackImage: string;
}

interface DownloadCTAProps {
  isDarkMode: boolean;
}

interface FooterProps {
  isDarkMode: boolean;
}

interface TestimonialProps {
  testimonial: {
    text: string;
    author: string;
  };
}


export default function LandingPage() {
  const [isClient, setIsClient] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(true)
  const { scrollYProgress } = useScroll()
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  })

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) {
    return null
  }

  return (
    <div className={`min-h-screen transition-colors duration-300 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}`}>
      <Background isDarkMode={isDarkMode} />
      <div className="relative z-10">
        <motion.div
          className="fixed top-0 left-0 right-0 h-1 bg-gradient-to-r from-blue-500 to-purple-500 origin-left z-50"
          style={{ scaleX }}
        />
        <Header isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
        <Hero isDarkMode={isDarkMode} />
        <BetterAlternative isDarkMode={isDarkMode} />
        <Testimonials isDarkMode={isDarkMode} />
        <Features isDarkMode={isDarkMode} />
        <DownloadCTA isDarkMode={isDarkMode} />
        <Pricing isDarkMode={isDarkMode} />
        <Footer isDarkMode={isDarkMode} />
      </div>
    </div>
  )
}

function Background({ isDarkMode }: { isDarkMode: boolean }) {
  return (
    <div className="fixed inset-0 z-0 overflow-hidden pointer-events-none">
      <div 
        className={`absolute inset-0 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} transition-colors duration-500`}
        style={{
          backgroundImage: `linear-gradient(to right, ${isDarkMode ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)'} 1px, transparent 1px), 
                             linear-gradient(to bottom, ${isDarkMode ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)'} 1px, transparent 1px)`,
          backgroundSize: '80px 80px'
        }}
      />
      <div className="absolute inset-0">
        <div className="absolute top-1/4 left-1/4 w-1/2 h-1/2 bg-blue-300 rounded-full mix-blend-multiply filter blur-3xl opacity-10" />
        <div className="absolute top-1/3 right-1/4 w-1/2 h-1/2 bg-purple-300 rounded-full mix-blend-multiply filter blur-3xl opacity-10" />
        <div className="absolute bottom-1/4 left-1/3 w-1/2 h-1/2 bg-green-300 rounded-full mix-blend-multiply filter blur-3xl opacity-10" />
      </div>
    </div>
  )
}

function Header({ isDarkMode, setIsDarkMode }: HeaderProps) {
  return (
    <header 
      className={`fixed top-0 left-0 right-0 z-40 ${isDarkMode ? 'bg-gray-800/80' : 'bg-white/80'} backdrop-blur-lg`}
    >
      <nav className="container mx-auto px-6 py-4 flex justify-between items-center">
        <div 
          className={`text-3xl font-extrabold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
        >
          ZenFocus
        </div>
        <div className="space-x-6 flex items-center">
          <a href="#features" className={`text-lg ${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-700 hover:text-gray-900'} transition-colors duration-300`}>Features</a>
          <a href="#pricing" className={`text-lg ${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-700 hover:text-gray-900'} transition-colors duration-300`}>Pricing</a>
          <a 
            href="https://discord.gg/dRfRPREVhW" 
            target="_blank" 
            rel="noopener noreferrer" 
            className={`text-lg ${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-700 hover:text-gray-900'} transition-colors duration-300`}
          >
            Support
          </a>
          <a 
            href="#download" 
            className="bg-gradient-to-r from-blue-500 to-purple-500 px-6 py-2 rounded-full text-white font-semibold transition-all duration-300 hover:shadow-glow hover:scale-105"
          >
            Download
          </a>
          <button
            onClick={() => setIsDarkMode(!isDarkMode)}
            className={`p-2 rounded-full ${isDarkMode ? 'bg-yellow-400 text-gray-900' : 'bg-gray-800 text-white'} transition-all duration-300 hover:shadow-glow hover:scale-105`}
          >
            {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
          </button>
        </div>
      </nav>
    </header>
  )
}

function Hero({ isDarkMode }: HeroProps) {
  return (
    <section className="pt-36 pb-28 relative overflow-hidden">
      <div className="container mx-auto px-6 text-center">
        <motion.h1 
          className="text-7xl font-extrabold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          Focus Like Never Before
        </motion.h1>
        <motion.p 
          className="text-2xl mb-10 max-w-2xl mx-auto"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          Elevate your productivity with ZenFocus. The ultimate tool for helping you start focus, & stay focused on the task at hand.
        </motion.p>
        <motion.a 
          href="#features" 
          className="bg-gradient-to-r from-blue-500 to-purple-500 px-12 py-4 rounded-full text-xl font-semibold inline-flex items-center text-white shadow-lg transition-all duration-300 hover:shadow-glow hover:scale-105"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
        >
          Explore ZenFocus
          <ArrowDown className="ml-3" />
        </motion.a>
      </div>
    </section>
  )
}

function BetterAlternative({ isDarkMode }: { isDarkMode: boolean }) {
  const alternatives = [
    { name: "Sessions", icon: Clock },
    { name: "Focused Work", icon: Target },
    { name: "Be Focused", icon: CheckCircle },
    { name: "Flow", icon: Zap },
    { name: "Balanced", icon: Activity },
  ];

  return (
    <section className="py-24">
      <div className="container mx-auto px-6">
        <div className="text-center mb-12">
          <motion.h2 
            className="text-4xl font-bold mb-2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>Better Alternative to</span>
          </motion.h2>
          <motion.div
            className="h-1 w-24 mx-auto bg-gradient-to-r from-blue-500 to-purple-500"
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          />
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
          {alternatives.map((app, index) => (
            <motion.div
              key={app.name}
              className={`flex flex-col items-center p-6 rounded-xl ${isDarkMode ? 'bg-gray-700' : 'bg-white'} shadow-lg transition-all duration-300 hover:shadow-glow hover:scale-105`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <app.icon size={48} className={`mb-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
              <span className={`text-sm font-semibold text-center ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>{app.name}</span>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}

function Testimonials({ isDarkMode }: { isDarkMode: boolean }) {
  const testimonials = [
    {
      text: "The dashboard is sick! It's so cool. I just donated $10 to your cause. This has a lot of promise.",
      author: "Anonymous Supporter"
    },
    {
      text: "Looks good, especially the floating timer. The visuals are dope!",
      author: "Reddit user"
    },
    {
      text: "Eye-pleasing and eloquently designed. Keep up the great work!",
      author: "Reddit user"
    },
    {
      text: "I just checked out Zenfocus and I'm loving the visuals and animations. I've been using SuperProductivity, but I always found the interface overwhelming. Zenfocus is a breath of fresh air in comparison - I can see myself using it long-term.",
      author: "Reddit user"
    }
  ]

  return (
    <section className="py-24">
      <div className="container mx-auto px-6">
        <div className="text-center mb-12">
          <motion.h2 
            className="text-4xl font-bold mb-2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>What Users Are Saying</span>
          </motion.h2>
          <motion.div
            className="h-1 w-24 mx-auto bg-gradient-to-r from-blue-500 to-purple-500"
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} testimonial={testimonial} isDarkMode={isDarkMode} index={index} />
          ))}
        </div>
      </div>
    </section>
  )
}

function TestimonialCard({ testimonial, isDarkMode, index }: TestimonialProps & { isDarkMode: boolean, index: number }) {
  return (
    <motion.div 
      className={`p-6 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-white'} shadow-lg relative overflow-hidden`}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      viewport={{ once: true }}
    >
      <div className="relative z-10">
        <svg className="w-12 h-12 text-gray-400 mb-4" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
        </svg>
        <p className={`text-lg mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>"{testimonial.text}"</p>
        <p className={`font-semibold ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          {testimonial.author}
        </p>
      </div>
      <div className="absolute top-0 right-0 -mt-6 -mr-6 w-24 h-24 bg-gradient-to-br from-blue-500 to-purple-500 opacity-50 rounded-full"></div>
    </motion.div>
  )
}

function Features({ isDarkMode }: FeaturesProps) {
  const features = [
    {
      title: "Task Management",
      description: "Easily add, organize, and track your tasks to stay on top of your goals.",
      icon: CheckCircle,
      color: "bg-green-500",
      details: [
        { icon: "plus.circle", text: "Add tasks quickly with a simple interface" },
        { icon: "tag", text: "Categorize tasks for better organization" },
        { icon: "arrow.up.arrow.down", text: "Prioritize what matters most" },
        { icon: "checkmark.circle", text: "Mark tasks as complete to track progress" }
      ],
      tip: "Use the '@' symbol when adding a task to quickly assign a category!",
      video: "/videos/Task.mp4"
    },
    {
      title: "Daily Planning",
      description: "Plan your day effectively and focus on what matters most.",
      icon: Calendar,
      color: "bg-yellow-500",
      details: [
        { icon: "list.bullet.rectangle", text: "Plan your day each morning" },
        { icon: "arrow.up.arrow.down", text: "Prioritize tasks for today's focus" },
        { icon: "calendar.badge.clock", text: "Easily move tasks between 'Someday' and 'Today'" },
        { icon: "chart.bar.fill", text: "Track daily progress towards your focus goal" }
      ],
      tip: "Start each day by reviewing and planning your tasks to stay focused and productive!",
      video: "/videos/Planning.mp4"
    },
    {
      title: "Daily Goal Setting",
      description: "Set and achieve your daily productivity targets.",
      icon: Target,
      color: "bg-purple-500",
      details: [
        { icon: "target", text: "Set personalized daily focus time goals" },
        { icon: "sliders", text: "Adjust goals based on your schedule and energy levels" },
        { icon: "trending-up", text: "Gradually increase your daily focus time" },
        { icon: "award", text: "Celebrate reaching your daily goals" }
      ],
      tip: "Start with a realistic goal and gradually increase it as you build your focus muscle!",
      video: "/videos/Goal.mp4"
    },
    {
      title: "Focus Sessions",
      description: "Dive into distraction-free work sessions and boost your productivity.",
      icon: Clock,
      color: "bg-red-500",
      details: [
        { icon: "play.circle", text: "Start a focus session for any task" },
        { icon: "bell", text: "Get reminders to stay on track" },
        { icon: "layers", text: "Always-on-top view keeps your current task visible" },
        { icon: "clock", text: "Track time spent on each task" }
      ],
      tip: "Use the always-on-top feature to keep your current task in view, even when switching between applications!",
      video: "/videos/Animation.mp4"
    },
    {
      title: "Break Timer",
      description: "Take structured breaks to maintain long-term productivity and well-being.",
      icon: Coffee,
      color: "bg-teal-500",
      details: [
        { icon: "alarm", text: "Automatic break reminders after focus sessions" },
        { icon: "hourglass", text: "Customizable break durations" },
        { icon: "refresh", text: "Rejuvenate your mind for sustained productivity" },
        { icon: "sun", text: "Get suggestions for refreshing break activities" }
      ],
      tip: "Use breaks to stretch, hydrate, or practice quick mindfulness exercises for optimal refreshment!",
      video: "/videos/Break.mp4"
    },
    {
      title: "Progress Tracking",
      description: "Visualize your productivity with detailed stats and insights.",
      icon: BarChart,
      color: "bg-indigo-500",
      details: [
        { icon: "calendar", text: "View daily and weekly progress" },
        { icon: "pie-chart", text: "Analyze time spent on different categories" },
        { icon: "trending-up", text: "Track goal completion rates" },
        { icon: "lightbulb", text: "Get personalized productivity insights" }
      ],
      tip: "Regularly review your progress to stay motivated and identify areas for improvement!",
      video: "/videos/Stats.mp4"
    }
  ]

  return (
    <section id="features" className="py-24">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <motion.h2 
            className="text-4xl font-bold mb-2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>Powerful Features</span>
          </motion.h2>
          <motion.div
            className="h-1 w-24 mx-auto bg-gradient-to-r from-blue-500 to-purple-500"
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          />
        </div>
        <div className="space-y-24">
          {features.map((feature, index) => (
            <FeatureSection key={index} feature={feature} index={index} isDarkMode={isDarkMode} />
          ))}
        </div>
      </div>
    </section>
  )
}

function FeatureSection({ feature, index, isDarkMode }: FeatureSectionProps) {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <motion.div 
      ref={ref}
      variants={containerVariants}
      initial="hidden"
      animate={controls}
      className={`flex flex-col lg:flex-row items-center ${index % 2 === 0 ? 'lg:flex-row-reverse' : ''} mb-24`}
    >
      <motion.div className="lg:w-1/2 mb-8 lg:mb-0" variants={itemVariants}>
        <div className="relative rounded-lg overflow-hidden shadow-2xl">
          <div className="absolute top-0 left-0 right-0 h-8 bg-gray-800 flex items-center px-4">
            <div className="flex space-x-2">
              <div className="w-3 h-3 bg-red-500 rounded-full" />
              <div className="w-3 h-3 bg-yellow-500 rounded-full" />
              <div className="w-3 h-3 bg-green-500 rounded-full" />
            </div>
            <div className="flex-grow text-center text-sm text-gray-400">ZenFocus</div>
          </div>
          <div className="pt-8">
            <VideoPlayer
              src={feature.video}
              fallbackImage="/placeholder.svg?height=720&width=1280"
            />
          </div>
        </div>
      </motion.div>
      <motion.div className="lg:w-1/2 lg:px-12" variants={itemVariants}>
        <motion.div className="flex items-center mb-6" variants={itemVariants}>
          <div className={`${feature.color} p-4 rounded-full mr-4`}>
            <feature.icon size={32} color="white" />
          </div>
          <h3 className="text-3xl font-bold">{feature.title}</h3>
        </motion.div>
        <motion.p 
          className={`text-lg mb-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}
          variants={itemVariants}
        >
          {feature.description}
        </motion.p>
        <motion.ul className="space-y-3 mb-6" variants={itemVariants}>
          {feature.details.map((detail, idx) => (
            <motion.li 
              key={idx}
              className="flex items-center"
              variants={itemVariants}
            >
              <div className={`${feature.color} p-1 rounded-full mr-3`}>
                <feature.icon size={16} color="white" />
              </div>
              <span className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>{detail.text}</span>
            </motion.li>
          ))}
        </motion.ul>
        <motion.div 
          className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}
          variants={itemVariants}
        >
          <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            <span className="font-bold">Tip:</span> {feature.tip}
          </p>
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

function VideoPlayer({ src, fallbackImage }: VideoPlayerProps) {
  const [videoError, setVideoError] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    const video = videoRef.current
    if (video) {
      video.addEventListener('loadedmetadata', () => {
        video.play().catch(() => {
          console.log("Autoplay prevented")
        })
      })
    }
  }, [])

  const handleVideoError = () => {
    console.error("Video failed to load:", src)
    setVideoError(true)
  }

  if (videoError) {
    return (
      <img
        src={fallbackImage}
        alt="Feature preview"
        className="w-full h-auto"
      />
    )
  }

  return (
    <video
      ref={videoRef}
      src={src}
      className="w-full h-auto"
      loop
      muted
      playsInline
      onError={handleVideoError}
    >
      Your browser does not support the video tag.
    </video>
  )
}

function DownloadCTA({ isDarkMode }: DownloadCTAProps) {
  return (
    <section id="download" className="py-24 relative z-10">
      <div className="container mx-auto px-6 text-center">
        <div className="text-center mb-16">
          <motion.h2 
            className="text-4xl font-bold mb-2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>Ready to Boost Your Productivity?</span>
          </motion.h2>
          <motion.div
            className="h-1 w-24 mx-auto bg-gradient-to-r from-blue-500 to-purple-500"
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          />
        </div>
        <motion.p 
          className={`text-xl mb-12 max-w-3xl mx-auto ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ once: true }}
        >
          Download ZenFocus now and start your journey to enhanced focus and productivity. Experience the power of focused deep-work sessions.
        </motion.p>
        <motion.a 
          href="https://beingpax.gumroad.com/l/ZenFocus" 
          target="_blank"
          rel="noopener noreferrer"
          className={`${isDarkMode ? 'bg-white text-blue-900' : 'bg-gradient-to-r from-blue-500 to-purple-500 text-white'} px-12 py-4 rounded-full text-xl font-semibold inline-flex items-center shadow-lg transition-all duration-300 hover:shadow-glow hover:scale-105`}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          viewport={{ once: true }}
        >
          Download for Mac
          <Download className="ml-3" />
        </motion.a>
      </div>
    </section>
  )
}

function Pricing({ isDarkMode }: { isDarkMode: boolean }) {
  return (
    <section id="pricing" className="py-24 relative z-10">
      <div className="container mx-auto px-6 text-center">
        <div className="text-center mb-16">
          <motion.h2 
            className="text-4xl font-bold mb-2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>Pricing</span>
          </motion.h2>
          <motion.div
            className="h-1 w-24 mx-auto bg-gradient-to-r from-blue-500 to-purple-500"
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          />
        </div>
        <p className={`text-xl mb-8 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
          ZenFocus is currently <span className="font-bold text-green-500">free</span> for all users!
        </p>
        <div className={`p-8 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} shadow-lg max-w-2xl mx-auto`}>
          <h3 className="text-2xl font-bold mb-4">A Personal Project with a Purpose</h3>
          <p className={`mb-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            I developed ZenFocus to meet my own productivity needs, and I'm excited to share it with you. As a personal project, it's currently free for everyone to use and enjoy.
          </p>
          <p className={`mb-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            While I may introduce paid features in the future to support ongoing development and server costs, I'm committed to keeping all basic features free. This way, everyone can benefit from improved productivity and focus.
          </p>
          <h3 className="text-2xl font-bold mb-4">Help Shape the Future of ZenFocus</h3>
          <p className={`mb-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            ZenFocus is still in its early stages, and I value your input. Your feedback and suggestions can help me improve and add features that matter most to you.
          </p>
          <a 
            href="https://discord.gg/dRfRPREVhW" 
            target="_blank" 
            rel="noopener noreferrer"
            className="bg-gradient-to-r from-blue-500 to-purple-500 text-white px-6 py-3 rounded-full font-semibold hover:from-blue-600 hover:to-purple-600 transition-all"
          >
            Join ZenFocus Discord Community
          </a>
        </div>
      </div>
    </section>
  )
}

function Footer({ isDarkMode }: FooterProps) {
  return (
    <footer className="py-16 relative z-10">
      <div className="container mx-auto px-6 text-center">
        <p className="text-lg">&copy; 2023 ZenFocus. All rights reserved.</p>
      </div>
    </footer>
  )
}